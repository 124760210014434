import axios from 'axios'
import { errorHandler } from '@/utils/errorHandler'

export const getMenu = async () => {
  const session_id = localStorage.getItem('sessionId')
  const request = await axios.get(
    '/proxy.php?className=menu&session_id=' + session_id
  )

  return await request.data
}

// export const getMainTitle = async () => {
//   try {
//     const request = await axios.get(
//       '/proxy.php?className=login&action=get_title_for_lkk'
//     )

//     return await request.data
//   } catch (error) {
//     throw new Error(error)
//   }
// }

export const getSteps = async (menuItemId) => {
  try {
    const request = await axios.get(
      `/proxy.php?className=questionnaire&action=get_lkk_pages&menu_item_id=${menuItemId}`
    )

    return await request.data
  } catch (error) {
    throw new Error(error)
  }
}

export const getPage = async ({ id, page } = {}) => {
  const session_id = localStorage.getItem('sessionId')

  const request = await axios.get('/proxy.php', {
    params: {
      className: 'menu',
      action: 'get_questionnaire',
      session_id,
      menu_item_id: id,
      page,
    },
  })

  return await request
}

export const saveQuestions = async (data) => {
  const session_id = localStorage.getItem('sessionId')
  let flatQuestions = []

  function flattenQuestions(arr = data.questions) {
    arr.forEach((el) => {
      if (el.subquestions && el.subquestions.length) {
        flattenQuestions(el.subquestions)
      }

      if (Array.isArray(el)) {
        el.forEach((i) => {
          if (i.subquestions) {
            i.subquestions.forEach((s) => {
              flatQuestions.push({
                delete: s.delete,
                answer_id: s.answer_id,
                id: s.etalon_id || s.id,
                value: Object.prototype.hasOwnProperty.call(s, 'hide_all')
                  ? s.value && s.value != 'Нет'
                    ? 'Да'
                    : 'Нет'
                  : s.value,
                answer_type: s.answer_type,
                additional: s.additional,
                sub_group_num: s.sub_group_num ? s.sub_group_num : 0,
                related_from_candidate_field: s.related_from_candidate_field,
              })
            })
          }

          flatQuestions.push({
            answer_id: i.answer_id,
            delete: i.delete,
            id: i.etalon_id || i.id,
            value: Object.prototype.hasOwnProperty.call(i, 'hide_all')
              ? i.value && i.value != 'Нет'
                ? 'Да'
                : 'Нет'
              : i.value,
            answer_type: i.answer_type,
            additional: i.additional,
            sub_group_num: i.sub_group_num,
            related_from_candidate_field: i.related_from_candidate_field,
          })
        })
      }

      if (el.answer_type !== 'file_answer' && el.question_type !== 'file') {
        flatQuestions.push({
          delete: el.delete,
          additional: el.additional,
          answer_id: el.answer_id,
          id: el.etalon_id || el.id,
          value: Object.prototype.hasOwnProperty.call(el, 'hide_all')
            ? el.value && el.value != 'Нет'
              ? 'Да'
              : 'Нет'
            : el.value,
          answer_type: el.answer_type,
          related_from_candidate_field: el.related_from_candidate_field,
        })
      }
    })
  }

  flattenQuestions()

  const params = new FormData()

  params.append('page_num', data.page_num)
  params.append('page_id', data.page_id)
  params.append('className', 'questionnaire')
  params.append('action', 'save')
  params.append('session_id', session_id)
  params.append('questions', JSON.stringify(flatQuestions))
  // if (data.files && Array.isArray(data.files)) {
  //   data.files.forEach((file) => params.append('questionnaire_files[]', file))
  // }
  if (data.final_page) params.append('final_page', true)

  try {
    const response = await fetch('/proxy.php', {
      method: 'POST',
      body: params,
    }).then((response) => response.json())

    return response
  } catch (e) {
    errorHandler(e)
  }
}

export const uploadFiles = async (data) => {
  const sessionId = localStorage.getItem('sessionId')
  const options = new FormData()

  if (data.files && Array.isArray(data.files)) {
    data.files.forEach((file) => options.append('questionnaire_files[]', file))
  }

  const request = await axios.post(
    `/proxy.php?className=questionnaire&action=save_files&session_id=${sessionId}&page_id=${data.page_id}&question_id=${data.question.id}`,
    options
  )

  return await request.data
}

export const removeFile = async (data) => {
  const session_id = localStorage.getItem('sessionId')

  const request = await axios.get('/proxy.php', {
    params: {
      className: 'questionnaire',
      action: 'delete_file',
      session_id,
      page_id: data.page_id,
      question_id: data.question_id,
      doc_id: data.doc_id,
      answer_id: data.answer_id,
    },
  })

  return await request.data
}
